.Footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 101;

    .content {
        background-color: rgba(22, 58, 76, 1);
        height: 37px;
        position: relative;
    }

    .links {
        text-align: center;
        z-index: 101;
        position: relative;

        .icon {
            height: 50px;
            margin: -17px 10px;
        }
    }

    .Oma {
        width: 250px;
        max-width: 45%;
        position: absolute;
        bottom: 13px;
        backdrop-filter: blur(1px);
        margin: 0;
        display: none;
        border-radius: 643px 494px 12px 41px;

        @media screen and (min-width: 600px) {
            margin: 20px 50px;
            display: block;
        }
    }

    .Punker {
        position: absolute;
        right: 0px;
        backdrop-filter: blur(1px);
        width: 250px;
        max-width: 45%;
        position: absolute;
        bottom: 8px;
        border-radius: 1019px 756px 37px 49px;
        margin: 0;
        display: none;

        @media screen and (min-width: 600px) {
            margin: 25px 50px;
            display: block;
        }
    }
}