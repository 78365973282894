.bandsSection {
    .infos {
        text-align: center;
        font-size: 25px;
        margin-top: 25px;
    }

    ul.cloud {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        line-height: 22px;

        @media screen and (min-width: 540px) {
            line-height: 40px;
        }

        margin: 0 auto;
        max-width: 700px;

        a[data-weight="1"] {
            --size: 1;
        }

        a[data-weight="2"] {
            --size: 2;
        }

        a[data-weight="3"] {
            --size: 3;
        }

        a[data-weight="4"] {
            --size: 4;
        }

        a[data-weight="5"] {
            --size: 5;
        }

        a[data-weight="6"] {
            --size: 6;
        }

        a[data-weight="7"] {
            --size: 7;
        }

        a[data-weight="8"] {
            --size: 8;
        }

        a[data-weight="9"] {
            --size: 9;
        }

        a {
            --size: 4;
            font-size: calc(var(--size) * 0.3rem);

            @media screen and (min-width: 540px) {
                font-size: calc(var(--size) * 0.3rem + 0.5rem);
            }

            @media screen and (min-width: 900px) {
                font-size: calc(var(--size) * 0.5rem + 0.5rem);
            }

            color: #fff;
            display: block;
            padding: 0.125rem 0.25rem;
            text-decoration: none;
            position: relative;

            &:hover,
            &:focus {
                color: #009999;
            }
        }
    }

    ul.cloud a::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 100%;
        background: white;
        transform: translate(-50%, 0);
        opacity: 0.15;
        transition: width 0.25s;
    }

    ul.cloud a:focus::before,
    ul.cloud a:hover::before {
        color: #009999;
        width: 100%;
    }
}

#bandbox {
    height: 50px;
}

.flyerAkustik {
    width: 100%;
    max-width: 600px;
}

.bigHeader {
    @media screen and (max-width: 435px) {
        margin-top: 20px;
    }
}