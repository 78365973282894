.bandBox {
    font-weight: bold;
    color: black;
    max-width: 1000px;
    margin: 15px auto;
    margin-bottom: 180px;
    background-color: white;
    border-radius: 30px;
    min-height: 50px;

    @media screen and (min-width: 600px) {
        margin: 50px auto;
        margin-bottom: 180px;
    }

    .content {
        width: 100%;

        .icon {
            height: 50px;
            margin: 0 10px;
        }

        .title {
            text-align: center;
            color: #009966;
            margin: 0px;
            padding-top: 20px;
            font-size: 30px;

            @media screen and (min-width: 600px) {
                font-size: 50px;
            }
        }

        .info {
            @media screen and (min-width: 900px) {
                margin-bottom: 30px;
            }

            text-align: center;
            font-size: 20px;
        }

        .box {
            @media screen and (min-width: 900px) {
                display: flex;
            }

            padding: 25px;
        }

        .text {
            flex: 1;
            margin: -20px 0 0 20px;
            font-size: 20px;
            text-align: center;

            .link {
                color: #009966;
            }
        }

        .bandPic {
            flex: 1;
            margin-bottom: 50px;

            @media screen and (min-width: 900px) {
                margin-bottom: 0px;
            }

            img {
                width: 100%;
            }
        }

    }
}