.articleBox {
    .BAK {
        color: #FF0198 !important;
    }

    .content {
        width: 100%;

        .subtitle {
            font-size: 18px;
            color: #909090;
        }

        .optionSelect {
            font-size: 20px;
            padding: 5px;
        }

        .amount {
            padding: 10px;
            font-size: 26px;
            font-weight: bold;
            color: #202020;
        }

        .icon {
            height: 50px;
            margin: 0 10px;
        }

        .title {
            text-align: center;
            color: #009966;
            margin: 0px;
            padding-top: 20px;
            font-size: 30px;

            @media screen and (min-width: 600px) {
                font-size: 50px;
            }
        }

        .info {
            @media screen and (min-width: 900px) {
                margin-bottom: 30px;
            }

            text-align: center;
            font-size: 20px;
        }

        .box {
            @media screen and (min-width: 900px) {
                display: flex;
            }

            padding: 25px;
        }

        .text {
            flex: 1;
            font-size: 20px;
            text-align: center;

            .link {
                color: #009966;
            }

            .wrapper {
                display: flex;
                height: 50px;
                max-width: 500px;
                margin: 20px auto;
                align-items: center;
                justify-content: space-between;
            }

            .wrapper .option {
                background: #fff;
                height: 100%;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                cursor: pointer;
                padding: 0 4px;
                margin: 0 3px;
                border: 2px solid #d6d6d6;
                transition: all 0.3s ease;

                @media screen and (min-width: 900px) {
                    padding: 0 10px;
                    margin: 0 10px;
                }

            }

            input[type="radio"] {
                display: none;
            }

            #option-0:checked:checked~.option-0,
            #option-1:checked:checked~.option-1,
            #option-2:checked:checked~.option-2,
            #option-3:checked:checked~.option-3,
            #option-4:checked:checked~.option-4,
            #option-5:checked:checked~.option-5,
            #option-6:checked:checked~.option-6,
            #option-7:checked:checked~.option-7,
            #option-8:checked:checked~.option-8 {
                border-color: #80ccb3;
                background: #80ccb3;
            }

            .BAK {

                #option-0:checked:checked~.option-0,
                #option-1:checked:checked~.option-1,
                #option-2:checked:checked~.option-2,
                #option-3:checked:checked~.option-3,
                #option-4:checked:checked~.option-4,
                #option-5:checked:checked~.option-5,
                #option-6:checked:checked~.option-6,
                #option-7:checked:checked~.option-7,
                #option-8:checked:checked~.option-8 {
                    border-color: #FF0198;
                    background: #FF0198;
                }
            }

            .wrapper .option span {
                font-size: 20px;
                color: #000;
                font-weight: bold;
            }

            #option-0:checked:checked~.option-0 span,
            #option-1:checked:checked~.option-1 span,
            #option-2:checked:checked~.option-2 span,
            #option-3:checked:checked~.option-3 span,
            #option-4:checked:checked~.option-4 span,
            #option-5:checked:checked~.option-5 span,
            #option-6:checked:checked~.option-6 span,
            #option-7:checked:checked~.option-7 span,
            #option-8:checked:checked~.option-8 span {
                color: #000;
                font-weight: bold;
            }
        }

        .orderControl {
            display: flex;
            justify-content: left;
            align-items: center;
            margin-top: 10px;

            .amountLabel {
                display: flex;
                justify-content: space-between;
            }

            .amount {
                background: #fff;
                height: 50px;
                width: 40px;
                display: flex;
                font-size: 20px;
                align-items: center;
                justify-content: space-evenly;
                margin: 0 10px;
                cursor: pointer;
                padding: 0 10px;
                border: 2px solid #d6d6d6;
                transition: all 0.3s ease;
            }

            .button {
                margin: 1px auto;
                width: 100%;
                height: 50px;
                border: none;
                background: #009966;
                border-radius: 5px;
                cursor: pointer;
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }

            .BAK {
                background: #FF0198;
                color: white !important;
            }
        }

        .articlePic {
            flex: 1;
            margin-bottom: 50px;
            position: relative;
            display: inline-block;
            /* <= shrinks container to image size */
            transition: transform 150ms ease-in-out;

            @media screen and (min-width: 900px) {
                margin-bottom: 0;
            }

            .soldOut {
                position: absolute;
                top: 43%;
                left: 0;

                &:hover {
                    transform: rotate(15deg);
                }
            }

            .slideshow {
                margin: 0 auto;
                overflow: hidden;
                max-width: 500px;
            }

            .slideshowSlider {
                white-space: nowrap;
                transition: ease 1000ms;
            }

            .slide {
                display: inline-block;
                width: 100%;
                border-radius: 40px;

            }

            img {
                width: 100%
            }

            /* Buttons */

            .slideshowDots {
                text-align: center;
            }

            .slideshowDot {
                display: inline-block;
                height: 20px;
                width: 20px;
                border-radius: 50%;

                cursor: pointer;
                margin: 15px 7px 0px;

                background-color: #c4c4c4;
            }

            .slideshowDot.active {
                background-color: #009966;
            }

            .BAK.slideshowDot.active {
                background-color: #FF0198;
            }
        }

    }
}