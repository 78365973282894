body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover,
a:active,
a:hover {
  font-weight: bold;
  color: #009966;
  text-decoration: none;
}

a:hover {
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background: rgb(22, 58, 76);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.main {
  background: linear-gradient(180deg, rgba(0, 152, 96, 1) 0%, rgba(22, 58, 76, 1) 100%);
  margin-bottom: 37px;
  padding: 10px;
  padding-top: 130px;

  @media screen and (min-width: 900px) {
    padding: 130px 50px 40px 50px;
  }

  @media screen and (min-width: 1200px) {
    padding: 130px;
  }
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #fff;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: #009966;
  text-shadow: -1px 0 #009966, 0 1px #009966, 1px 0 #009966, 0 -1px #009966;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 50px;
  right: 3px;
  top: 3px;

  @media screen and (min-width: 600px) {
    width: 50px;
    right: 14px;
    top: 14px;
  }

  @media screen and (min-width: 900px) {
    width: 90px;
    right: 50px;
    top: 35px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  padding: 6.5em 1.5em 0;
  font-weight: bold;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  //fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #fff;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

.bm-menu-wrap {
  top: 0px;
  width: 320px !important;
  background: rgba(0, 0, 0, 0.7);
}