.bakStyle {
    color: #ff0198;
}

.shopSection {
    font-weight: bold;
    color: black;
    max-width: 1000px;
    margin: 15px auto;
    margin-bottom: 180px;
    background-color: white;
    border-radius: 30px;
    min-height: 50px;

    .navigation {
        display: flex;
        list-style: none;
        font-size: 18px;
        margin: 0 auto;
        max-width: 300px;
        padding: 0;
        padding-top: 15px;
        justify-content: space-around;

        @media screen and (min-width: 600px) {
            width: 55%;
            max-width: none;
            font-size: 30px;
            margin: 10px auto;
        }

        .trenner {
            margin-left: 2px;

            @media screen and (min-width: 380px) {
                margin-left: 20%;
            }

            @media screen and (min-width: 600px) {
                margin-left: 2px;
            }

            @media screen and (min-width: 900px) {
                margin-left: 10px;
            }

            @media screen and (min-width: 1200px) {
                margin-left: 20px;
            }
        }
    }


    @media screen and (min-width: 600px) {
        margin: 50px auto;
        margin-bottom: 180px;
    }

    .loading {
        width: 100%;

        img {
            margin: 0 auto;
            display: block;
        }
    }
}