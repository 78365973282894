.SponsorenContainer {
    margin-bottom: 150px;

    a,
    a:hover,
    a:active,
    a:hover {
        font-weight: bold;
        color: #fff;
        text-decoration: underline;
    }
    a:hover {
        color: #000;
      }
}

.sponsorenText {
    margin-bottom: 50px;
}

.SponsorenList {
    @media screen and (min-width: 460px) {
        display: flex;
    }

    border-top: 1px solid #8b967d;
}

.SponsorenList2 {
    @media screen and (min-width: 460px) {
        display: flex;
        flex-direction: row-reverse;
    }

    border-top: 1px solid #8b967d;
}

.sponsorImage {
    width: 200px;
    margin: 25px;
}