.Header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    height: 130px;
    background-color: rgba(1, 145, 94, .9);

    &.openMenu {
        backdrop-filter: unset;
    }

    .headerTitle {
        position: absolute;
        top: 75px;
        text-align: center;
        width: 100%;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        font-size: 25px;

        @media screen and (min-width: 600px) {
            top: 40px;
            text-align: left;
            left: 205px;
            font-size: 25px;
        }

        @media screen and (min-width: 900px) {
            font-size: 32px;
            top: 40px;
            text-align: center;
            left: 0;
        }

        @media screen and (min-width: 1200px) {
            font-size: 50px;
            top: 15px;
        }
    }

    .headerTitleBig {
        position: absolute;
        top: 75px;
        text-align: center;
        width: 100%;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        font-size: 25px;

        @media screen and (min-width: 600px) {
            top: 40px;
            text-align: left;
            left: 205px;
            font-size: 20px;
        }

        @media screen and (min-width: 900px) {
            font-size: 25px;
            top: 40px;
            text-align: center;
            left: 0;
        }

        @media screen and (min-width: 1050px) {
            font-size: 32px;
            top: 40px;
            text-align: center;
            left: 0;
        }

        @media screen and (min-width: 1355px) {
            font-size: 50px;
            top: 15px;
        }
    }

    .HeaderPunker {
        position: absolute;
        top: 14px;
        right: 30px;
        height: 78px;

        @media screen and (min-width: 600px) {
            display: none;
        }

    }

    .HeaderOma {
        position: absolute;
        left: 46px;
        height: 65px;
        top: 28px;

        @media screen and (min-width: 600px) {
            display: none;
        }
    }

    .Logo {
        margin: 20px auto;
        display: block;
        max-width: 106px;
        position: relative;
        z-index: 101;

        @media screen and (min-width: 600px) {
            margin: 30px;
            max-width: 170px;
        }

        @media screen and (min-width: 900px) {
            max-width: 200px;
            margin: 20px 50px;
        }
    }

    .cartLogo {
        position: absolute;
        height: 50px;
        left: 19px;
        top: 3px;

        @media screen and (min-width: 600px) {
            height: 50px;
            right: 14px;
            top: 70px;
            left: auto;
        }

        @media screen and (min-width: 900px) {
            height: 95px;
            right: 161px;
            top: 30px;
        }

    }

    .cartCounter {
        border-radius: 50%;
        background-color: #009966;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: #000;
        cursor: pointer;
        position: fixed;
        border: 1px solid #000;
        top: 21px;
        left: 45px;
        width: 15px;
        height: 15px;
        font-size: 10px;

        @media screen and (min-width: 600px) {
            right: 22px;
            left: unset;
            top: 90px;
            width: 15px;
            font-size: 10px;
            height: 15px;
        }

        @media screen and (min-width: 900px) {
            right: 173px;
            left: unset;
            top: 74px;
            width: 26px;
            height: 26px;
            font-size: 14px;
        }
    }
}

@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
    .Header {
        background-color: transparent;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
}