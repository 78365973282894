.home {
    text-align: center;
    margin-top: -110px;

    .startAni {
        position: relative;
        top: 110px;

        .logo {
            margin: 0 auto;
            display: block;
            width: 100%;
            max-width: 350px;
        }

        .gif {
            max-width: 1000px;
            width: 100%;
            position: relative;
            margin: 0 auto;
            display: block;

            @media screen and (min-width: 600px) {
                top: -110px;
            }
        }

        .location {
            position: relative;
            font-size: 40px;

            @media screen and (min-width: 600px) {
                top: -110px;
            }
        }
    }

    .links {
        margin-bottom: 210px;
        position: relative;
        z-index: 100;

        @media screen and (max-width: 600px) {
            position: relative;
            top: 110px;
        }
    }

    .flyer {
        max-width: 100%;

        @media screen and (min-width: 600px) {
            margin: 10px;
        }
    }

    .info {
        font-size: 32px;
        position: relative;

        @media screen and (min-width: 600px) {
            top: -80px;
        }
    }

    .icon {
        width: 50px;
        padding: 15px;
    }
}