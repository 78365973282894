.faq {
  width: 100%;
  margin-bottom: 130px;
  overflow: hidden;

  &__title {
    margin: 0;
    padding-bottom: 25px;
  }

  &__question {
    width: 100%;
    border-bottom: 1px solid #ddd;

    a,
    a:hover,
    a:active,
    a:hover {
      font-weight: bold;
      color: #fff;
      text-decoration: none;
    }
  }

  &__question-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
    font-size: 21px;
    padding-top: 18px;
    padding-bottom: 18px;
    color: 'blue';
    font-weight: bold;

    &::after {
      content: url(./../../assets/images/icon-arrow-down.svg);
      margin-right: 17px;
      transform: rotate(90deg);
      transition: transform 300ms ease-out;
    }
  }

  &__desc {
    margin-left: 40px;
    padding-right: 17px;
    margin-top: 0;
    margin-bottom: 0;
    height: 0;
    overflow: hidden;
    transition: all 200ms ease-out;
  }

  &__img {
    width: 100%;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 600px) {
      width: 500px;
    }
  }
}

.showDescription {
  height: 100%;
  margin-bottom: 15px;
  max-width: 100%;
}

.fontWeightBold {
  font-weight: bold;

  &::after {
    transform: rotate(720deg);
  }
}