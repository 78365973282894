.impressumContend {
  margin-bottom: 200px;
  overflow: hidden;

  a,
  a:hover,
  a:active,
  a:hover {
    font-weight: bold;
    color: #fff;
    text-decoration: underline;
  }

  a:hover {
    color: #000;
  }

  .arrow {
    height: 13px;
    position: relative;
    left: 5px;
    top: 2px;

    @media(min-width: 600px) {
      height: 20px;
    }

    &.active {
      transform: rotate(-180deg);
    }

    transition: transform 550ms ease;
  }

  .clickable {
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .animatedContainer {
    animation: swing-in-top-fwd 550ms cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  }
}