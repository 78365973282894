.VereinContainer {
    margin-bottom: 150px;

    a,
    a:hover,
    a:active,
    a:hover {
        font-weight: bold;
        color: #fff;
        text-decoration: underline;
    }

    a:hover {
        color: #000;
    }
}