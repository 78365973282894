.cartBox {
    .content {
        margin: 0;
        padding: 0;
        height: 100%;

        .CartContainer {
            display: flex;
            flex-direction: column;
        }

        .CartHeader {
            margin: auto;
            width: 90%;
            height: 15%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .Heading {
            font-size: 20px;
            font-weight: bold;
            color: #2F3841;
        }

        .Action {
            font-size: 14px;
            color: #009999;
            cursor: pointer;
            border-bottom: 1px solid #009999;
        }

        .Cart-Items {
            margin: auto;
            width: 90%;
            height: 30%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .image-box {
            width: 15%;
            text-align: center;

            img {
                width: 100%;
            }
        }

        .about {
            height: 100%;
            width: 50%;
        }

        .title {
            font-size: 20px;

            @media screen and (min-width: 600px) {
                font-size: 32px;
            }
        }

        .subtitle {
            font-size: 12px;

            @media screen and (min-width: 600px) {
                font-size: 18px;
            }

            color: #909090;
        }

        .counter {
            width: 15%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .btn {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #d9d9d9;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: #202020;
            cursor: pointer;
        }

        .rabat {
            font-size: 10px;
            color: red;
        }

        .count {
            font-size: 20px;
            color: #202020;
        }

        .prices {
            height: 100%;
            text-align: right;
        }

        .amount {
            padding-top: 20px;
            font-size: 13px;
            font-weight: bold;
            color: #202020;

            @media screen and (min-width: 600px) {
                font-size: 26px;
            }
        }

        .save {
            padding-top: 5px;
            font-size: 14px;
            color: #1687d9;
            cursor: pointer;
        }

        .remove {
            padding-top: 5px;
            font-size: 14px;
            color: #009999;
            cursor: pointer;
        }

        .pad {
            margin-top: 5px;
        }

        hr {
            width: 66%;
            float: right;
            margin-right: 5%;
        }

        .checkout {
            margin: 25px;

            @media screen and (min-width: 600px) {
                width: 28%;
                margin-left: 67%;
                margin-bottom: 25px;
            }
        }

        .total {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .shipping {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .Subtotal {
            font-size: 22px;
            font-weight: bold;
            color: #202020;
        }

        .agbContainer {
            margin: 20px 0;

            label,
            input {
                cursor: pointer;
            }
        }

        .items {
            font-size: 16px;
            color: #909090;
            line-height: 10px;
        }

        .total-amount {
            font-size: 36px;
            font-weight: bold;
            color: #202020;
        }


        .shipping,
        .cost-amount {
            font-size: 20px;
            font-weight: bold;
            color: #202020;
        }

        .button {
            margin-top: 10px;
            width: 100%;
            height: 40px;
            border: none;
            background: #009966;
            border-radius: 10px;
            cursor: pointer;
            font-size: 16px;
            color: #202020;
        }

    }
}