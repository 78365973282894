.articleListBox {
    .BAK {
        a {
            color: #FF0198 !important;
        }
    }

    .content {
        margin: 0;
        padding: 0;
        height: 100%;

        .CartContainer {
            display: flex;
            flex-direction: column;
        }

        .Cart-Items {
            margin: auto;
            width: 90%;
            height: 30%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .image-box {
            width: 15%;
            text-align: center;
            position: relative;

            img {
                width: 100%;
            }

            .soldOut {
                position: absolute;
                top: 45%;
                left: 0;

                &:hover {
                    transform: rotate(15deg);
                }
            }
        }

        .about {
            height: 100%;
            width: 50%;
        }

        .title {
            font-size: 20px;

            @media screen and (min-width: 600px) {
                font-size: 32px;
            }
        }

        .subtitle {
            font-size: 12px;

            @media screen and (min-width: 600px) {
                font-size: 18px;
            }

            color: #909090;
        }

        .prices {
            height: 100%;
            text-align: right;
        }

        .amount {
            padding-top: 20px;
            font-size: 14px;
            font-weight: bold;
            color: #202020;

            @media screen and (min-width: 600px) {
                font-size: 26px;
            }
        }
    }
}